<template>
  <div class="app-content" :data-theme="getTheme">
    <router-view/>
  </div>
</template>

<script>
  export default {
    name: "mainApp",
    computed: {
      getTheme() {
        return this.$store.getters.getTheme;
      }
    },
    methods: {
      setTheme() {
        const localTheme = localStorage.getItem("theme");

        if (!localTheme) {
          return;
        }

        this.$store.commit("setTheme", localTheme);
      },
      setViewportHeight() {
        document.documentElement.style.setProperty("--viewport-height", `${window.innerHeight}px`);
      },
    },
    mounted() {
      this.setTheme();
      this.setViewportHeight();

      window.addEventListener("resize", this.setViewportHeight);
    }
  }
</script>

<style lang="scss" src="@/assets/scss/global.scss"></style>