import { createStore, } from "vuex";

export default createStore({
    state: {
        aiVersion: "gpt-4o",
        chat: "assisten",
        theme: "light",
        showSideMenu: false,
        userData: {},
        chats: {
            assisten: [],
            image: [],
            code: [],
        },
    },
    getters: {
        getAIVersion: (state) => {
            if (state.chat === "image") {
                return "dall-e-3";
            }

            return state.aiVersion;
        },
        getActiveChatMessages: (state) => state.chats[state.chat] || [],
        getActiveChat: (state) => state.chat,
        getShowSideMenu: (state) => state.showSideMenu,
        getTheme: (state) => state.theme,
        getUserData: (state) => state.userData,
    },
    mutations: {
        addMessageToActiveChat(state, message) {
            state.chats[state.chat].push(message);
        },
        editMessageInActiveChat(state, { key, value, index, }) {
            const activeMessage = state.chats[state.chat][index];

            state.chats[state.chat].splice(index, 1, { ...activeMessage, [key]: value, });
        },
        clearAllChats(state) {
            state.chats = {
                assisten: [],
                image: [],
                code: [],
            };
        },
        setShowSideMenu(state, val) {
            state.showSideMenu = val;
        },
        setUserData(state, { key, val, }) {
            state.userData[key] = val;
        },
        clearUserData(state) {
            state.userData = {};
        },
        setActiveChat(state, val) {
            state.chat = val;
        },
        setTheme(state, val) {
            state.theme = val;

            localStorage.setItem("theme", val);
        }
    }
});