<template>
    <div class="auth h-screen">
        <div class="d-flex flex-column h-100">
            <vHeader />
            <main class="flex-grow-1">
                <router-view />
            </main>
        </div>
    </div>
</template>
  
<script>
  import vHeader from "@/components/vAuthHeader.vue";

  export default {
    name: "authTemplate",
    components: {
        vHeader,
    },
  }
</script>

<style scoped lang="scss" src="@/assets/scss/auth.scss"></style>