<template>
    <div class="d-flex h-screen main-wrapper">
      <vMenu />
      <main class="flex-grow-1 main">
        <div class="main__content">
          <router-view />
        </div>
      </main>
    </div>
  </template>
  
  <script>
    import vMenu from "@/components/vMenuBlockComponent.vue";
  
    export default {
      name: "vMainTemplate",
      components: {
        vMenu,
      },
      mounted() {
        const username = window.$cookies.get("username");

        if (!username) {
          return;
        }

        this.$store.commit("setUserData", { key: "username", val: username, });
      },
    }
  </script>
  
<style scoped lang="scss" src="@/assets/scss/main.scss"></style>