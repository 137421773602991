<template>
    <div class="news">
        <vPostModalWindow 
            v-if="showModalWindow" 
            :show="showModalWindow" 
            :post="activePost" 
            @leave="showModalWindow = false"
        />
        <div class="news__wrapper">
            <header class="pa-6 d-flex align-center news__header">
                <h3 class="news__header-title">AI Новости</h3>
                <button class="news__header-menu-btn" @click="$store.commit('setShowSideMenu', true)">
                    <svg class="icon-fill" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="0.9375" width="16.125" height="1.75" rx="0.875" fill="#161A1C"/>
                      <rect x="0.9375" y="7" width="16.125" height="1.75" rx="0.875" fill="#161A1C"/>
                      <rect x="0.9375" y="14" width="16.125" height="1.75" rx="0.875" fill="#161A1C"/>
                    </svg>
                </button>
            </header>
            <main class="news__main">
                <PerfectScrollbar>
                    <ul class="news__list">
                        <li v-for="post in news" class="news__list-item" :key="post.id" @click="openPost(post)">
                            <h4 class="news__list-item-date">{{ post.date }}</h4>
                            <div class="news__list-item-content">
                                <img 
                                    v-if="post.image" 
                                    class="news__list-item-image" 
                                    :src="require(`@/assets/img/news/${post.image}`)" 
                                    :alt="post.title"
                                >
                                <p class="news__list-item-desc">{{ post.title }}</p>
                            </div>
                        </li>
                    </ul>
                </PerfectScrollbar>
            </main>
        </div>
    </div>
</template>

<script>
    import vPostModalWindow from "@/components/vPostModalWindow.vue";

    export default {
        name: "vNewsPage",
        components: {
            vPostModalWindow,
        },
        data: () => ({
            activePost: {},
            showModalWindow: false,
            news: [
                {
                    id: 0,
                    date: "22 августа 2024",
                    title: "ИИ создал кухонного шеф-повара — встретьте RoboChef!",
                    image: "image114.png",
                    desc: `Каждый из нас мечтал бы иметь личного шеф-повара, который готовил бы нам вкусные блюда каждый день. И вот, благодаря Искусственному Интеллекту, эта мечта стала реальностью! Компания TechCulina объявила о выпуске RoboChef — первого в мире кухонного робота, полностью управляемого ИИ.<br/><br/>RoboChef оснащён передовыми сенсорами и камерами, которые позволяют ему следить за процессом готовки, а также распознавать и оценивать свежесть продуктов. Блюда, созданные RoboChef, уже получили высшие оценки кулинарных критиков за их безупречный вкус и подачу. Более того, шеф-робот способен обучаться новым рецептам, тем самым постоянно расширяя своё "кулинарное меню".<br/><br/>Интересно, что RoboChef не только готовит, но и учит пользователей. С помощью специального приложения можно следить за процессом приготовления и учиться у шеф-робота кулинарным премудростям!<br/><br/>RoboChef обещает изменить наши представления о том, что возможно на современной кухне. Об этом роботе уже говорят как о будущем гастрономии.`,
                },
                {
                    id: 1,
                    date: "21 августа 2024",
                    title: "Искусственный интеллект оживил утерянные голоса легендарных музыкантов!",
                    image: "image115.png",
                    desc: `Сегодня Корпорация Синергия представила революционный проект "Эхо Веков", который позволяет воссоздавать голоса давно ушедших легенд музыкальной индустрии с помощью искусственного интеллекта. Этот проект открыл двери к будущему, где мы можем услышать новые песни от таких икон, как Фредди Меркьюри, Элвис Пресли или Эми Уайнхаус.<br/><br/>
Используя мощные алгоритмы машинного обучения и огромные базы данных записей выступлений, искусственный интеллект анализирует все нюансы и интонации голоса и создает точную звуковую модель. В итоге получается так, что современное оборудование позволяет не только слушать записи, но и генерировать новые композиции, которые исполнялись бы этими великими артистами.<br/><br/>
Разработчики уже пополнили библиотеку "Эха Веков" известными хитами в интерпретации этих легендарных голосов и работают над созданием новых треков в разных жанрах. Представьте себе, как звучит новый дуэт Фредди и Эми! Это становится реальностью благодаря достижениям в области искусственного интеллекта и машинного обучения.`,
                },
                {
                    id: 2,
                    date: "20 августа 2024",
                    title: "ИИ помогает раскрывать преступления: новые возможности для полиции",
                    desc: `Сегодня речь пойдет о невероятно захватывающей инновации в мире искусственного интеллекта, которая способна буквально изменить подход к правоохранительной деятельности. Исследовательская группа из Университета Кейптауна представила новую систему ИИ, которая анализирует записи с камер наблюдения и определяет подозрительные активности с высокой точностью.<br/><br/>
Одним из самых интересных аспектов данной технологии является то, что ИИ не просто фиксирует людей на видео. Он анализирует сразу несколько факторов: поведение, траекторию передвижения, соотношение времени и места действий, а также многие другие параметры. Например, если кто-то бродит вокруг припаркованных автомобилей слишком долго или совершает манипуляции, которые визуально напоминают взлом, система сразу уведомит операторов о подозрительной активности.<br/><br/>
Особенно впечатляют первые испытания системы в реальных условиях. В одном из британских городов ИИ помог раскрыть кражу уже через 10 минут после ее совершения, идентифицировав подозрительного человека и незамедлительно сигнализировав полиции.<br/><br/>
Эта технология не просто помогает полицейским реагировать на преступления быстрее, но и может значительно снизить количество ложных вызовов и улучшить общую безопасность городов.`,
                },
                {
                    id: 3,
                    date: "19 августа 2024",
                    title: "ИИ научил коров ходить на пастбище по GPS!",
                    desc: `Друзья, сегодня у нас весьма необычная новость из мира сельского хозяйства! В одной из ферм в Новой Зеландии начали использовать искусственный интеллект для управления передвижением коров. Да-да, вы не ослышались! Теперь буренки могут пастись, следуя за виртуальными загонщиками, которые направляют их с помощью GPS и специальных ошейников.<br/><br/>
Система работает следующим образом: на каждой корове установлен GPS-ошейник, который отслеживает ее местоположение и передает данные в облако. Искусственный интеллект анализирует эти данные и определяет оптимальные маршруты для перемещения стада, минимизируя стресс для животных и повышая эффективность выпаса. Если корова отклоняется от заданного маршрута, ошейник издает сигнал, направляя ее в нужное место.<br/><br/>
Фермеры отмечают, что такая технология существенно упрощает их труд и позволяет лучше контролировать состояние пастбищ, предотвращая их перенаселение. В будущем планируется расширить функционал системы, добавив возможности мониторинга здоровья животных и предсказания вспышек заболеваний.<br/><br/>
Кто бы мог подумать, что настойчивый помощник-робот доберется и до пастбищ! 🌾🐄`,
                },
                {
                    id: 4,
                    date: "18 августа 2024",
                    title: "ИИ Превращает Звания в Музыкальные Шедевры",
                    desc: `Сегодня в мире ИИ произошла настоящая музыкальная революция! Ведущие учёные и разработчики из института музыки и технологий MIT презентовали новейшую разработку - искусственный интеллект, способный превращать любой текст в мелодии. Теперь пользователи могут скормить ИИ любой отрывок текста, стиха или даже заголовок новостей, и программа создаст на его основе уникальную музыкальную композицию.<br/><br/>
Но это ещё не всё! ИИ не просто собирает слова в песни, он учитывает контекст, эмоции и интонацию текста, создавая по-настоящему эмоциональные и цепляющие произведения. Например, вдохновившись сонетом Шекспира, ИИ может создать романтическую балладу, а новостной заголовок о спортивной победе может стать ярким и динамичным треком.<br/><br/>
Одним из первых удачных примеров стал музыкальный трек, созданный из первоапрельской шутки о нашествии пингвинов на Нью-Йорк. Композиция, наполненная забавными и лёгкими нотками, мгновенно стала вирусной и покорила миллионы слушателей. Теперь пользователи активно делятся своим творчеством, создавая уникальные композиции на основе личных дневников, переписок и даже случайных мыслей.<br/><br/>
Этот инновационный подход обещает изменить не только музыкальную индустрию, но и нашу повседневную жизнь, делая её более мелодичной и яркой.`,
                },
                {
                    id: 5,
                    date: "17 августа 2024",
                    title: "ИИ помог расшифровать загадочный язык древней цивилизации!",
                    desc: `Научный мир потрясен: исследователи из Массачусетского технологического института (MIT) применили искусственный интеллект для расшифровки одного из самых таинственных языков древности — линеар A. Этот язык использовался в Минойской цивилизации, существовавшей на острове Крит около 1450 года до нашей эры, и долгое время оставался нерасшифрованным.<br/><br/>
ИИ проанализировал сотни артефактов и надписей, сравнивая их с уже известными древними языками. На основании статистических моделей и алгоритмов машинного обучения, ИИ смог предложить интерпретацию десятков неизвестных символов и слов. В результате учёные получили ключевые элементы языка, которые могут помочь пролить свет на культуру и образ жизни минойцев.<br/><br/>
Этот прорыв открывает новые горизонты для археологии и лингвистики, позволяя быстрее и точнее расшифровывать древние тексты и находить утраченные знания прошлого.`,
                },
                {
                    id: 6,
                    date: "16 августа 2024",
                    title: "Сборка ИИ-автомобиля за 24 часа: реальность от стартапа RoboAuto",
                    desc: `Приветствуем всех наших подписчиков! Сегодня я расскажу вам о невероятной истории стартапа RoboAuto, который поставил новый рекорд по скорости создания автономного транспортного средства.<br/><br/>
В своем недавнем проекте команда RoboAuto подготовила и собрала полностью автономный автомобиль всего за 24 часа! Применив самые передовые технологии искусственного интеллекта и робототехники, специалисты RoboAuto показали, на что действительно способны современные ИИ-решения.<br/><br/>
Процесс создания автомобиля включал в себя не только механическую сборку, но и машинное обучение, которое позволило "обучить" авто распознавать дорожные знаки и реагировать на изменения в окружающей среде. Все это стало возможным благодаря уникальному алгоритму, разработанному командой из Кремниевой долины.<br/><br/>
Чтобы продемонстрировать свои возможности, RoboAuto провела тест-драйв, во время которого автомобиль успешно преодолел сложный маршрут, включая оживленные городские улицы и узкие загородные дороги. Эксперты отметили, что это одно из наиболее значимых достижений в области автономного транспорта за последние годы.<br/><br/>
Этот проект вдохновляет и других инновационных компаний стремиться к новым высотам и размывать границы возможного. Будущее автопрома как никогда близко, и с каждым днем ИИ становится все более интегрированным в нашу повседневную жизнь.`,
                },
                {
                    id: 7,
                    date: "15 августа 2024",
                    title: "ИИ раскрывает тайны древнеегипетских мумий",
                    image: "image112.png",
                    desc: `Сегодня мы с радостью сообщаем об ошеломляющем прорыве в археологии с использованием Искусственного Интеллекта! Учёные из Университета Оксфорда представили ИИ-алгоритм, который сумел проникнуть сквозь тысячелетия и "рассмотреть" внутреннее строение древнеегипетских мумий, не разрушая их. Алгоритм анализирует изображения, полученные с помощью высокоразрешающей томографии, и создает трёхмерные модели, которые могут воспроизвести внутренние органы и ткани в мельчайших деталях.<br/><br/>
Этот подход помог обнаружить удивительные детали: от следов древних хирургических вмешательств до отсутствия органов, которые египтяне часто удаляли в процессе мумификации. Теперь историки и биологи могут более точно реконструировать образ жизни и причины смерти людей, живших тысячи лет назад. С помощью ИИ мы буквально сможем заглянуть в прошлое и понять, как медицина и религиозные практики влияли на древние цивилизации.`,
                },
                {
                    id: 8,
                    date: "14 августа 2024",
                    title: "Инновационный стартап Payman AI привлек $3 миллиона на развитие платежных систем",
                    desc: `Стартап Payman AI завершил раунд финансирования, привлекая $3 миллиона для дальнейшего развития своих инновационных решений в области платежных систем, основанных на искусственном интеллекте. Эта успешная инвестиция позволит компании ускорить интеграцию ИИ в финтех-сектор и разработку новых инструментов, облегчающих финансовые транзакции для пользователей по всему миру. Напомним, что Payman AI вошел в топ-12 стартапов с наибольшими перспективами в области ИИ.<br/><br/>
Не упустите из виду другие новости из мира ИИ! Например, популярный ресурс Bitcoin.com News ищет журналиста для создания ежедневного контента об ИИ и криптовалютах`,
                },
                {
                    id: 9,
                    date: "13 августа 2024",
                    title: "Новый карманный ноутбук GPD Pocket 4 с искусственным интеллектом: революция в мире технологий!",
                    image: "image117.png",
                    desc: `Сегодня мы представляем вашему вниманию необычную новинку – модульный, трансформируемый и компактный ноутбук GPD Pocket 4. Это устройство действительно выделяется из всех существующих аналогов благодаря своему уникальному дизайну и невероятным возможностям.<br/><br/>
Но что еще интереснее, этот карманный ноутбук стал первым устройством, оснащенным чипом Ryzen AI 300. Это делает его не просто очередным гаджетом, а настоящим технологическим прорывом. Усиленная мощь и производительность позволяют проводить сложные вычислительные задачи и обрабатывать огромные объемы данных на ходу, что делает этот ноутбук идеальным инструментом для студентов, журналистов и IT-профессионалов.<br/><br/>
GPD Pocket 4 отличается своей модульностью, что означает возможность подстраивать его под свои нужды. Хотите большой экран? Подключите его. Нужно больше памяти? Установите модуль. Эта гибкость и адаптивность – будущее технологий.<br/><br/>
Особенное внимание заслуживает встроенный искусственный интеллект на базе Ryzen AI 300. Разработчики обещают беспрецедентные возможности в обработке данных, повышенной безопасности и улучшенной производительности. Этот ИИ способен на лету адаптироваться под пользователя, оптимизируя работу и экономя время.<br/><br/>
С таким устройством ваши возможности становятся безграничными. Как вам перспектива иметь мощный компьютер в кармане, который будет адаптироваться под любые задачи?<br/><br/>
Это только начало новой эры компактных и мощных устройств с искусственным интеллектом. Будьте первыми, кто откроет для себя все преимущества GPD Pocket 4!`,
                },
                {
                    id: 10,
                    date: "12 августа 2024",
                    title: "Искусственный интеллект и музыка: как алгоритмы меняют музыкальную индустрию",
                    desc: `Сегодня стало известно, что платформу Spotify буквально заполонили сгенерированные музыкальные композиции. Эксперты отмечают, что алгоритмы ИИ научились создавать музыку, которая не уступает по качеству произведениям реальных композиторов.<br/><br/>
Что это значит для обычных пользователей? Во-первых, это открывает двери к широкому разнообразию аудиоконтента, ведь генеративные модели могут создавать мелодии любой сложности и жанра. Во-вторых, такие технологии могут помочь начинающим музыкантам искать вдохновение и даже создавать новые произведения при помощи ИИ.<br/><br/>
Однако, есть и обратная сторона медали. Скептики опасаются, что изобилие сгенерированного контента может затруднить поиск настоящих произведений талантливых артистов среди потока роботизированной музыки. Это поднимает вопросы о будущем музыкальной индустрии и правовом регулировании использования ИИ в творчестве.<br/><br/>
Интересно, что этот тренд уже привлек внимание не только меломанов, но и юристов, которые обсуждают, как будут лицензироваться такие музыкальные произведения и кому будет принадлежать авторское право.<br/><br/>
Следите за нашими новостями, и мы продолжим рассказывать вам об удивительных способах использования ИИ в мире!`,
                },
                {
                    id: 11,
                    date: "11 августа 2024",
                    title: "Робот-бариста: Умные машины теперь готовят кофе",
                    image: "image116.png",
                    desc: `В Японии представили новую разработку: робот-бариста, который может приготовить идеально сбалансированный эспрессо или капучино. Этот искусственный интеллект не только умеет правильно подбирать помол и дозировку, но и оценивает свежесть зерен и температуру воды. Представьте, заходите вы в кафе, а вам навстречу движется механический "бариста", который может побеседовать с вами о последних новинках литературы или порекомендовать интересный стартап!<br/><br/>
Робот использует специальные алгоритмы, чтобы подстроиться под вкусы каждого клиента, анализирует их предыдущие заказы и даже может предлагать новые рецепты встречающихся редко сочетаний вкусов. Искусственный интеллект быстрее, точнее и, главное, всегда в хорошем настроении!<br/><br/>
Первые роботы-бариста уже зарекомендовали себя в крупных городах Японии, и производитель планирует выходить на мировой рынок. Кто знает, возможно, очень скоро искусственный интеллект будет готовить вам утренний кофе в вашем любимом кафе?`,
                },
            ],
        }),
        methods: {
            leave() {
                this.showModalWindow = false;
            },
            openPost(post) {
                this.activePost = post;
                this.showModalWindow = true;
            }
        }
    }
</script>

<style lang="scss" scoped src="@/assets/scss/news.scss"></style>