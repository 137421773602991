export default {
    data: () => ({
        isMobile: false,
        size: 744,
    }),
    mounted() {
        this.isMobile = window.innerWidth <= this.size;
    
        window.addEventListener("resize", () => {
          this.isMobile = window.innerWidth <= this.size;
        });
    },
};