<template>
    <div class="modal-window">
        <PerfectScrollbar>
            <div class="modal-window__wrapper">
                <div class="modal-window__head">
                    <h5 class="modal-window__date">{{ post.date }}</h5>
                    <button class="modal-window__cross-btn" @click="$emit('leave')">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.49977 6.49879C6.8345 6.16405 7.62888 6.16663 7.96362 6.50137L11.999 10.499L15.999 6.49902C16.3338 6.16429 17.1643 6.16663 17.499 6.50137C17.8338 6.8361 17.8338 7.66429 17.499 7.99902L13.499 11.999L17.499 15.999C17.8338 16.3338 17.8338 17.1643 17.499 17.499C17.1643 17.8338 16.3719 17.8338 16.0372 17.499L11.999 13.499L7.99902 17.499C7.66429 17.8338 6.83376 17.8338 6.49902 17.499C6.16429 17.1643 6.16714 16.3338 6.50188 15.999L10.499 11.999L6.50038 8.00014C6.16565 7.6654 6.16503 6.83352 6.49977 6.49879Z" fill="#7B7D82"/>
                        </svg>
                    </button>
                </div>
                <div class="modal-window__main">
                    <h2 class="modal-window__title">{{ post.title }}</h2>
                    <img 
                        v-if="post.image" 
                        class="modal-window__image" 
                        :src="require(`@/assets/img/news/${post.image}`)" 
                        :alt="post.title"
                    />
                    <p class="modal-window__desc" v-html="post.desc"></p>
                </div>
            </div>
        </PerfectScrollbar>
    </div>
</template>

<script>
    export default {
        name: "vPostModalWindow",
        props: {
            show: {
                type: Boolean,
                required: true,
            },
            post: {
                type: Object,
                required: true,
            },
        },
    };
</script>

<style lang="scss" scoped src="@/assets/scss/modalWindow.scss"></style>